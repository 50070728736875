import React, { useState, useEffect } from 'react';
import {useNavigate, useLocation, Routes, Route } from 'react-router-dom';
import {createBrowserRouter,RouterProvider } from 'react-router-dom';
import AbsenceCointainer from '../component/AbsenceContainer';
import PageNotFound from '../component/PageNotFound';
import Headers from '../component/header/Header';
import { useDispatch, useSelector } from 'react-redux';
import * as constant from '../constants/Constant';
import { signin } from '../slices/authenticationSlice';

const startTime = performance.now();

const RootRouter = ({reporter}) => {
    const navigate = useNavigate(),
    location = useLocation(),
    dispatch = useDispatch(),
    authenticationData = useSelector((state) => state.authenticationData),
    header = useSelector((state) => state.header),
    summary = useSelector((state) => state.summary),
    [loadTime, setLoadTime]= useState(null);
    
    const apiSignIn = (code) => {
        dispatch(signin({authorizationCode: code}))
    }

    const redirectToIdms = () => {
        let authorizationUrl = `${constant.idms_auth}${constant.client_id}&redirect_uri=${constant.redirect_uri}&response_type=code&state=state&scope=${constant.scope}`;
        window.location.replace(authorizationUrl);
    }

    async function getAuthenticationDetails() {
        const search = window.location.search,
            params = new URLSearchParams(search),
            code = params.get("code");
            //code = 'c7999b0be75254068becb3651cae67409';

        console.log("-------code------", code)

        if(code) {
            await apiSignIn(code);
        } else {
            await redirectToIdms();
        }
    }

    useEffect(() => {
        getAuthenticationDetails()
    }, [])

    useEffect(() => {
        if(authenticationData.isSigninSuccess) {
            navigate("/")
        }
    }, [authenticationData.isSigninSuccess])

    useEffect(() => {
        const endTime = performance.now(),
            loadTimeInSeconds = (endTime - startTime) / 1000;

        if(!header.isApplyClicked) {
            if(authenticationData.isSigninSuccess && (header.data ?. length > 0) && (summary.data ?. length > 0)) {
                setLoadTime(loadTimeInSeconds.toFixed(2));
            }
        }
    }, [authenticationData.isSigninSuccess, header.data, summary.data]);

    useEffect(() => {
        let summaryData = summary.data ?. length > 0 ? summary.data[0] : "",
            maskedDsId = summaryData ? summaryData.mask_Emp_Ds_Id : "";
            
        reporter.reportEvent({
            eventType: 'load',
            controlId: 'loading personal absence dashboard',
            controlValue: 'load',
        });

        if(maskedDsId && loadTime) {
            console.log(`---------Load Time: ${loadTime} sec ----------`)
            reporter.reportCustomKeys({
                userRole: 'market support',
                customkey1: maskedDsId,
                customkey2: 'R001',
                customkey3: `${loadTime} sec`
            });
        }
    }, [loadTime, summary.data])

    return (
        <div>
            {
                authenticationData.isSigninSuccess ?
                <Routes>
                    <Route exact path="/" element={<div><Headers /><AbsenceCointainer /></div>} />
                    <Route path="*" element={<PageNotFound />} />
                </Routes>
                :
                <></>
            }
        </div>
    )
}
export default RootRouter;